exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-atimo-js": () => import("./../../../src/pages/cases/atimo.js" /* webpackChunkName: "component---src-pages-cases-atimo-js" */),
  "component---src-pages-cases-cervogia-js": () => import("./../../../src/pages/cases/cervogia.js" /* webpackChunkName: "component---src-pages-cases-cervogia-js" */),
  "component---src-pages-cases-code-and-soul-js": () => import("./../../../src/pages/cases/code-and-soul.js" /* webpackChunkName: "component---src-pages-cases-code-and-soul-js" */),
  "component---src-pages-cases-favori-js": () => import("./../../../src/pages/cases/favori.js" /* webpackChunkName: "component---src-pages-cases-favori-js" */),
  "component---src-pages-cases-firstoeat-js": () => import("./../../../src/pages/cases/firstoeat.js" /* webpackChunkName: "component---src-pages-cases-firstoeat-js" */),
  "component---src-pages-cases-gunner-js": () => import("./../../../src/pages/cases/gunner.js" /* webpackChunkName: "component---src-pages-cases-gunner-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-cases-mercado-de-terras-js": () => import("./../../../src/pages/cases/mercado-de-terras.js" /* webpackChunkName: "component---src-pages-cases-mercado-de-terras-js" */),
  "component---src-pages-cases-principal-papelaria-js": () => import("./../../../src/pages/cases/principal-papelaria.js" /* webpackChunkName: "component---src-pages-cases-principal-papelaria-js" */),
  "component---src-pages-cases-trelo-js": () => import("./../../../src/pages/cases/trelo.js" /* webpackChunkName: "component---src-pages-cases-trelo-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-insights-template-js": () => import("./../../../src/pages/insights/template.js" /* webpackChunkName: "component---src-pages-insights-template-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */)
}

