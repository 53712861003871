import React from 'react';

const Logo = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 26.381"
            className={ props.class }
        >
            <path
                d="M107.969,1298.9l-3.388-8.271-.038.09,3.4,8.181Z"
                transform="translate(-91.135 -1283.632)"
            />
            <path
                d="M101.788,1298.557h.044l2.555-6.344Z"
                transform="translate(-91.732 -1283.289)"
            />
            <path
                d="M104.452,1285.086l-10.931,25.689h5.72l2.332-5.719-2.332,5.719h0l4.336-10.676,2.6-6.344.746-1.854.006.017.038-.09,3.388,8.271h-.031l4.43,10.676h5.8l-10.932-25.689Z"
                transform="translate(-93.522 -1284.831)"
            />
            <path
                d="M113.588,1290.411h7.761v20.332h5.611v-20.332h7.762v-5.175H113.588Z"
                transform="translate(-89.316 -1284.799)"
            />
            <path
                d="M149.581,1299.849c0,4.044-2.078,6.12-5.5,6.12s-5.5-2.15-5.5-6.3v-14.43h-5.612v14.575c0,7.509,4.191,11.333,11.041,11.333s11.186-3.789,11.186-11.514v-14.394h-5.611Z"
                transform="translate(-85.259 -1284.799)"
            />
            <path
                d="M167.4,1284.877a13.191,13.191,0,0,0-13.591,13.192v.071c0,7.251,5.647,13.119,13.519,13.119a13.189,13.189,0,0,0,13.591-13.19v-.074C180.921,1290.743,175.273,1284.877,167.4,1284.877Zm7.653,13.263a7.631,7.631,0,0,1-7.653,7.946,7.751,7.751,0,0,1-7.725-8.017v-.074a7.629,7.629,0,0,1,7.653-7.943,7.749,7.749,0,0,1,7.725,8.017Z"
                transform="translate(-80.921 -1284.877)"
            />
        </svg>
    )
}

export default Logo;