import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import AOS from 'aos';
import 'aos/dist/aos.css';

const ThemeContext = React.createContext({
    cookies: false,
    theme: 'home'
});

const resize = () => typeof window !== 'undefined' && window.innerHeight;

const ThemeContextProvider = ({ children, location }) => {

    const [cookies, setCookie] = useCookies(['weather']);
    const [theme, setTheme] = useState('');
    const [height, setHeight] = useState(resize)

    useEffect(() => {

        window.addEventListener("resize", () => setHeight(resize));

        AOS.init({
          duration: 1000,
          offset: height * 0.1,
          mirror: true,
          anchorPlacement: "top-bottom",
          disable: "mobile",
        });
        
        AOS.refresh({
            offset: height * 0.1,
        });

        if (location) {

            const path = location.pathname.split('/');

            setTheme(path[1]);

        }

    }, [location, height]);

    // useEffect(() => {

    //     if (cookies && cookies.length > 0) {
    //         return;
    //     }

    //     const fetchWeather = () => {

    //         if ('geolocation' in navigator) {

    //             navigator.geolocation.getCurrentPosition((position) => {

    //                 fetch(
    //                     `https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&       units=metric&lang=pt_br&appid=19b88266723e91970b4996ace4a18113`
    //                 )
    //                 .then((response) => response.json())
    //                 .then((resultData) => {

    //                     setCookie('weather', JSON.stringify(resultData), { path: '/' });

    //                 })
    //                 .catch((error) => console.log(error));

    //             });
    //         }
    //     };

    //     fetchWeather();

    // }, []);

    return (
        <ThemeContext.Provider
            value={{
                cookies,
                theme
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeContext, ThemeContextProvider };
