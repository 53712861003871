import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Logo from './logo';
import LinkTransition from './linkTransition';

import IconInstagramBlack from '../assets/iconInstagramBlack.inline.svg';
import IconLinkedinBlack from '../assets/iconLinkedinBlack.inline.svg';
import IconMediumBlack from '../assets/iconMediumBlack.inline.svg';
import IconWhatsappBlack from '../assets/iconWhatsappBlack.inline.svg';

import NavigateData  from '../data/header-navegation.json';

const Header = ({ location }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {
        active ?
            (document.querySelector("html").style.overflow = "hidden")
            :
            (document.querySelector("html").style.overflow = "auto")
    }, [active])

    const { pathname } = location;

    const isWhite = pathname.split('/').length > 2 && (pathname.includes('cases') || pathname.includes('insights'));
    const isBlack = pathname.split('/').length <= 2;

    return (
      <header className="fixed z-10 py-8 md:py-4 w-full bg-black">
        <div className="container flex justify-between items-center">
          <div className="flex items-center w-full">
            <LinkTransition to="/" className="mr-10 z-10">
              <span className="sr-only">ATUO</span>

              <Logo
                class={classNames({
                  "h-4 md:h-6": true,
                  "fill-yellow": !active,
                  "fill-black": active,
                })}
              />
            </LinkTransition>

            <nav
              className={classNames({
                "main-menu md:justify-end": true,
                isActive: active,
              })}
            >
              <div className="items flex flex-col md:flex-row">
                <LinkTransition
                  to="/"
                  title=""
                  className={classNames({
                    "md:hidden mr-10 text-l font-semibold md:font-light text-black md:text-white": true,
                    "border-b-4 pb-1 border-black md:border-0 md:text-yellow transition":
                      pathname === "/",
                  })}
                  onClick={() => setTimeout(() => setActive(false), 1000)}
                >
                  Home
                </LinkTransition>

                {NavigateData.map((item, index) => (
                  <LinkTransition
                    key={item.name}
                    to={item.href}
                    title={item.title}
                    className={classNames({
                      "mr-10 text-l font-semibold md:font-light text-black md:text-white": true,
                      "border-b-4 pb-1 border-black md:border-0 md:text-yellow transition":
                        pathname === item.href,
                      "ml-0 md:ml-auto": index < 1,
                    })}
                    onClick={() => setTimeout(() => setActive(false), 1000)}
                  >
                    {item.name}
                  </LinkTransition>
                ))}
              </div>

              <div className="md:hidden flex items-end">
                <a
                  href="https://www.linkedin.com/company/agenciaatuo"
                  title=""
                  className="mr-6"
                >
                  <IconLinkedinBlack />
                </a>
                <a
                  href="https://www.instagram.com/agenciaatuo"
                  title=""
                  className="mr-6"
                >
                  <IconInstagramBlack />
                </a>
                <a
                  href="https://medium.com/ag%C3%AAncia-atuo"
                  title=""
                  className="mr-6"
                >
                  <IconMediumBlack />
                </a>
                <a href="https://wa.me/5515991423442" title="" className="mr-6">
                  <IconWhatsappBlack />
                </a>
              </div>
            </nav>
          </div>

          <button
            className={classNames({
              "mobile-menu": true,
              isActive: active,
            })}
            onClick={() => setActive(!active)}
          >
            Menu
          </button>
        </div>
      </header>
    );
};

export default Header;
