import React from 'react';

import Header from '../components/header';
import { ThemeContextProvider } from '../context/theme-context';

const Template = ({ location, children }) => {

    return (

        <ThemeContextProvider location={location}>

            <Header location={location} /> 

            {children}

        </ThemeContextProvider>

    );
};

export default Template;