import React, { useEffect, useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const resize = () => typeof window !== 'undefined' && window.innerWidth > 768 ? false : true;

const LinkTransition = ({ children, ...props }) => {

    const [isMobile, setIsMobile] = useState(resize)

    useEffect(() => {
        window.addEventListener("resize", () => setIsMobile(resize));
    }, [isMobile]);

    return (

        <AniLink
            cover
            duration={1}
            {...props}
            bg={isMobile ? "#1b1d1d" : "#febe01" }
        >

            {children}

        </AniLink>
    );
};

export default LinkTransition;